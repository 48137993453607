import React, { useState } from 'react';
import './ExerciseCard.css';

interface Exercise {
  type: string;
  content: string;
  audioUrl: string;
  tip: string;
  ipaCode: string;
}

interface ExerciseCardProps {
  exercise: Exercise;
}

interface SyllableScore {
  syllable: string;
  score: number;
}

interface WordScore {
  word: string;
  syllables: SyllableScore[];
  overallScore: number;
}

interface Report {
  accuracyScore: number;
  fluencyScore: number;
  pronScore: number;
  words: WordScore[];
}

const ExerciseCard: React.FC<ExerciseCardProps> = ({ exercise }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState<Report | null>(null);
  const [recordingUrl, setRecordingUrl] = useState<string | null>(null);

  const handlePlayAudio = () => {
    console.log('Playing audio:', exercise.audioUrl);
  };

  const handleRecordAudio = () => {
    if (!isRecording) {
      setIsRecording(true);
      // Start recording logic
    } else {
      setIsRecording(false);
      setIsLoading(true);
      // Simulate API call for speech recognition
      setTimeout(() => {
        setIsLoading(false);
        setReport({
          accuracyScore: 85,
          fluencyScore: 90,
          pronScore: 88,
          words: [
            {
              word: 'thin',
              syllables: [
                { syllable: 'th', score: 75 },
                { syllable: 'in', score: 95 },
              ],
              overallScore: 85,
            },
            {
              word: 'tin',
              syllables: [
                { syllable: 't', score: 98 },
                { syllable: 'in', score: 97 },
              ],
              overallScore: 97,
            },
          ],
        });
        setRecordingUrl('path/to/recording.mp3');
      }, 2000);
    }
  };

  const getScoreColor = (score: number) => {
    if (score >= 90) return 'score-excellent';
    if (score >= 75) return 'score-good';
    return 'score-needs-improvement';
  };

  const getScoreIcon = (score: number) => {
    if (score >= 90) return '🌟';
    if (score >= 75) return '👍';
    return '💪';
  };

  const getReportSummary = (report: Report) => {
    const overallScore = Math.round((report.accuracyScore + report.fluencyScore + report.pronScore) / 3);
    if (overallScore >= 90) return "Excellent pronunciation! Keep up the great work!";
    if (overallScore >= 75) return "Good job! Focus on improving your weaker sounds for even better results.";
    return "You're making progress! Practice the challenging sounds more to see improvement.";
  };

  const renderExerciseContent = () => {
    if (!report) return exercise.content;

    return report.words.map((word, index) => (
      <span key={index} className="word-container">
        {word.syllables.map((syllable, sIndex) => (
          <span key={sIndex} className={`syllable ${getScoreColor(syllable.score)}`}>
            {syllable.syllable}
          </span>
        ))}
        {index < report.words.length - 1 && " - "}
      </span>
    ));
  };

  return (
    <div className="exercise-card">
      <div className="card-content">
        <span className="exercise-type">{exercise.ipaCode}</span>
        <div className="exercise-content-wrapper">
          <h2 className="exercise-content">{renderExerciseContent()}</h2>
          <button className="play-button" onClick={handlePlayAudio} aria-label="Play audio">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
              <path d="M8 5v14l11-7z" />
            </svg>
          </button>
        </div>
        <div className="tip-section">
          <h3>Practice Tip</h3>
          <p>{exercise.tip}</p>
        </div>
        <button
          className={`record-button ${isRecording ? 'recording' : ''}`}
          onClick={handleRecordAudio}
        >
          {isRecording ? 'STOP RECORDING' : 'START RECORDING'}
        </button>
        {isLoading && <div className="loading">Analyzing your pronunciation...</div>}
        {recordingUrl && (
          <div className="recording-player">
            <h3>Your Recording</h3>
            <audio controls src={recordingUrl}>
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
        {report && (
          <div className="report-section">
            <h3>Pronunciation Report</h3>
            <p className="report-summary">{getReportSummary(report)}</p>
            <div className="score-grid">
              <div className={`score-item ${getScoreColor(report.accuracyScore)}`}>
                <span>Accuracy</span>
                <span className="score">{report.accuracyScore}% {getScoreIcon(report.accuracyScore)}</span>
              </div>
              <div className={`score-item ${getScoreColor(report.fluencyScore)}`}>
                <span>Fluency</span>
                <span className="score">{report.fluencyScore}% {getScoreIcon(report.fluencyScore)}</span>
              </div>
              <div className={`score-item ${getScoreColor(report.pronScore)}`}>
                <span>Overall</span>
                <span className="score">{report.pronScore}% {getScoreIcon(report.pronScore)}</span>
              </div>
            </div>
            <h4>Word Breakdown</h4>
            <div className="word-grid">
              {report.words.map((word, index) => (
                <div key={index} className={`word-item ${getScoreColor(word.overallScore)}`}>
                  <span>{word.word}</span>
                  <span className="score">{word.overallScore}% {getScoreIcon(word.overallScore)}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExerciseCard;