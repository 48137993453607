import React from 'react';
import './CountrySelection.css';

interface Country {
  name: string;
  code: string;
}

interface CountrySelectionProps {
  onCountrySelect: (country: Country) => void;
}

const countries: Country[] = [
  { name: 'Brazil', code: 'BR' },
  { name: 'China', code: 'CN' },
  { name: 'India', code: 'IN' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Russia', code: 'RU' },
];

const CountrySelection: React.FC<CountrySelectionProps> = ({ onCountrySelect }) => {
  return (
    <div className="country-selection">
      <h2>Select Your Country of Origin</h2>
      <div className="flag-container">
        {countries.map((country) => (
          <button
            key={country.code}
            className="flag-button"
            onClick={() => onCountrySelect(country)}
          >
            <img
              src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${country.code.toLowerCase()}.svg`}
              alt={`${country.name} flag`}
            />
            <p className="country-name">{country.name}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default CountrySelection;