import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import CountrySelection from './components/CountrySelection';
import SoundSelection from './components/SoundSelection';
import ExerciseCard from './components/ExerciseCard';

interface Country {
  name: string;
  code: string;
}

interface Sound {
  name: string;
  exercises: Exercise[];
}

interface Exercise {
  type: string;
  content: string;
  audioUrl: string;
  tip: string;
  ipaCode: string; // Add this line
}

const App: React.FC = () => {
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedSound, setSelectedSound] = useState<Sound | null>(null);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);

  const handleCountrySelect = (country: Country) => {
    setSelectedCountry(country);
    setSelectedSound(null);
    setCurrentExerciseIndex(0);
  };

  const handleSoundSelect = (sound: Sound) => {
    setSelectedSound(sound);
    setCurrentExerciseIndex(0);
  };

  return (
    <div className="App">
      <Header selectedCountry={selectedCountry} />
      <main className="main-content">
        {!selectedCountry ? (
          <CountrySelection onCountrySelect={handleCountrySelect} />
        ) : !selectedSound ? (
          <SoundSelection country={selectedCountry} onSoundSelect={handleSoundSelect} />
        ) : (
          <ExerciseCard
            exercise={selectedSound.exercises[currentExerciseIndex]}
          />
        )}
      </main>
    </div>
  );
}

export default App;