import React from 'react';
import './Header.css';

interface HeaderProps {
  selectedCountry: { name: string; code: string } | null;
}

const Header: React.FC<HeaderProps> = ({ selectedCountry }) => {
  return (
    <header className="app-header">
      <div className="logo-container">
        <h1>Prosody</h1>
        {selectedCountry && (
          <span className="selected-country">
            <img
              src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
              alt={`${selectedCountry.name} flag`}
              width="20"
              height="15"
            />
            {selectedCountry.name}
          </span>
        )}
      </div>
    </header>
  );
};

export default Header;