import React from 'react';
import './SoundSelection.css';

interface Exercise {
  type: string;
  content: string;
  audioUrl: string;
  tip: string;
  ipaCode: string; // Add this line
}

interface Sound {
  name: string;
  exercises: Exercise[];
}

interface Country {
  name: string;
  code: string;
}

interface SoundSelectionProps {
  country: Country;
  onSoundSelect: (sound: Sound) => void;
}

// Mock data for sounds (replace with actual data from your backend)
const mockSounds: Sound[] = [
  {
    name: 'Th sounds (/θ/ and /ð/)',
    exercises: [
      { 
        type: 'Word Pair', 
        content: 'thin - tin', 
        audioUrl: 'path/to/audio1.mp3',
        tip: 'Place your tongue between your teeth for "th" sounds.',
        ipaCode: '/θ/' // Add this line
      },
      { 
        type: 'Sentence', 
        content: 'Think about that thin cat.', 
        audioUrl: 'path/to/audio2.mp3',
        tip: 'Practice saying "th" words slowly, then gradually increase your speed.',
        ipaCode: '/ð/' // Add this line
      },
    ],
  },
  // Add more sounds here with appropriate ipaCode values
];

const SoundSelection: React.FC<SoundSelectionProps> = ({ country, onSoundSelect }) => {
  return (
    <div className="sound-selection">
      <h2>Select a Sound to Practice</h2>
      <div className="sound-list">
        {mockSounds.map((sound, index) => (
          <button
            key={index}
            className="sound-button"
            onClick={() => onSoundSelect(sound)}
          >
            {sound.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SoundSelection;